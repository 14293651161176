<template>
<div v-if="payment" class="payment-flow-content vuetify">
    <div v-if="$vuetify.display.lgAndUp" class="payment-flow-content-desktop-header">
        <base-btn
            @click="needHelpBottomSheet = true"
            class="need-help-sheet-btn"
            variant="outlined"
        >{{ $translate('labels.needHelp') }}</base-btn>
    </div>
    <router-view
        @back="back"
        @cancel="cancelPaymentDialog = true"
        @leave-curae="leaveCurae"
        @cancel-curae="cancelCurae"
        @continue-with-access-one="continueWithAccessOne"
        @continue-with-curae="continueWithCurae"
        @existing-curae-account="existingCuraeAccount"
        @get-curae-offers="getCuraeOffers"
        @loaded="validateCurrentStepIsValid"
        @process-payment="processPaymentInternal"
        @select-curae-offer="selectCuraeOffer"
        @step="onClickEditLink"
        @update-amount="updateAmount"
        @update-date="updateDate"
        @update-method="updateMethod"
        @set-getting-financing="setIsGettingFinancingOptions"
        @scroll-to-top="scrollToTop"
        :allow-estimate-partial-payments="allowEstimatePartialPayments"
        :access-one-financing-options="validAccessOneFinancingOptions"
        :allow-plan-update="allowPlanUpdate"
        :already-has-financing="alreadyHasFinancing"
        :amount="payment.amount"
        :amount-option-type="payment.amountOptionType"
        :billing="payment.billing"
        :bill-payment-options="billPaymentOptions"
        :card-processing-available="cardProcessingAvailable"
        :cardholder-agreement="curaeCardholderAgreement"
        :curae-financing-options="validCuraeFinancingOptions"
        :curae-selected-option="selectedCuraeOption"
        :curae-selected-option-index="selectedCuraeOptionIndex"
        :current-plan-data="currentAccount ? currentAccount.currentPlan : null"
        :current-provider="currentProvider"
        :date="payment.isDateValid() ? $formatDate(payment.date, 'YYYY-MM-DD', 'YYYY/MM/DD') : null"
        :e-check-processing-available="eCheckProcessingAvailable"
        :editable-date="!shouldHideDate"
        :estimate-id="estimateId"
        :estimate-requested-amount="estimateRequestedAmount"
        :estimate-requested-amount-percent="estimateRequestedAmountPercent"
        :estimate-requested-total="estimateRequestedTotal"
        :existing-application="existingCuraeApplication"
        :existing-curae-option="validCuraeFinancingOptions ? validCuraeFinancingOptions[0] : {}"
        :financing-disclaimers="financingDisclaimers"
        :financing-error="financingError"
        :financing-processing-available="financingProcessingAvailable"
        :financing-vendors-available="financingVendors"
        :generic-error="confirmationGenericError"
        :has-apple-pay-enabled="hasApplePayEnabled"
        :has-financing-option="hasFinancingOption"
        :installment-count="payment.installmentCount"
        :is-estimate="Boolean(payment.estimateId)"
        :is-getting-financing-options="isGettingFinancingOptions"
        :is-sub-account="Boolean(payment.voucherNumber && !payment.estimateId)"
        :is-processing="isProcessing"
        :max-date-for-discount-policy="payment.maxDateForDiscountPolicy"
        :max-schedule-days="maxScheduleDays"
        :method="payment.method"
        :method-error="confirmationMethodError"
        :min-amount-for-transaction="minAmountForTransaction"
        :min-partial-amount="minPartialAmount"
        :usaepay-public-key="usaepayPublicKey"
        :payment-plan-installment-options="installmentOptions"
        :providerName="currentProvider.name"
        :providerPhoneNumber="currentProvider.billingPhoneNumber"
        :no-offers-found="noOffersFound"
        :provider-name="currentProvider.name"
        :secure-code="this.currentAccount.secureCode"
        :should-prevent-payment-plan-creation="shouldPreventPaymentPlanCreation"
        :saved-methods="savedMethods"
        :statement-amount="statementAmount()"
        :sub-account-id="subAccountId"
        :total-amount="totalAmount()"
        :has-bad-debt="hasBadDebt"
        :has-financing-default="hasFinancingDefault"
        :update-plan-data="(currentAccount && allowPlanUpdate && payment.installmentCount && payment.installmentCount > 1) ? currentAccount.currentPlan : null"
        class="payment-flow-content-step-container"
    ></router-view>
    <base-bottom-sheet v-model="needHelpBottomSheet" content-class="payment-flow-content-need-help-sheet need-help-sheet">
        <card-header
            :title="$translate('labels.needHelp')"
            icon="mdi-hospital-box-outline"
            class="sheet-card-header"
        ></card-header>
        <need-help
            @composemessage="composeMessage"
            :provider="currentProvider"
        ></need-help>
    </base-bottom-sheet>
    <base-dialog v-model="cancelPaymentDialog" content-class="cancel-payment">
        <template #title>{{ $translate('paymentFlow.confirmCancel.cancelPayment') }}</template>
        <template #text>{{ $translate('paymentFlow.confirmCancel.paymentNotProcessed') }}</template>
        <template #actions>
            <base-btn class="cancel-btn" variant="text" @click="cancelPaymentDialog = false">{{ $translate('paymentFlow.confirmCancel.noContinuePaying') }}</base-btn>
            <base-btn class="continue-btn v-button-primary" variant="text" @click="cancel()">{{ $translate('paymentFlow.confirmCancel.yesCancelPayment') }}</base-btn>
        </template>
    </base-dialog>
    <base-dialog v-model="noOffersFoundDialog" content-class="no-offers-found" @on-close="leaveFinancingFlow">
        <template #title>{{ $translate('paymentFlow.financing.noOffers.title') }}</template>
        <template #text>{{ $translate('paymentFlow.financing.noOffers.body') }}</template>
        <template #actions><base-spacer></base-spacer></template>
    </base-dialog>
    <base-dialog v-model="redirectingDialog" content-class="redirecting">
        <template #title>{{ $translate('paymentFlow.financing.redirect.title') }}</template>
        <template #text>{{ $translate('paymentFlow.financing.redirect.body') }}</template>
        <template #actions>
            <base-btn class="continue-btn v-button-primary" variant="text" :href="financingRedirectUrl" target="_blank">{{ $translate('paymentFlow.financing.redirect.action') }}</base-btn>
        </template>
    </base-dialog>
    <base-dialog v-model="clearBalancePendingDialog" content-class="clear-balance-pending" @on-close="cancel">
        <template #title>{{ $translate('paymentFlow.amount.clearBalancePendingTitle') }}</template>
        <template #text>{{ $translate('paymentFlow.amount.clearBalancePendingText') }}</template>
        <template #actions>
            <base-btn class="continue-btn v-button-primary" variant="text" @click="() => {clearBalancePendingDialog = false; cancel()}">{{ $translate('actions.close') }}</base-btn>
        </template>
    </base-dialog>
    <base-dialog v-model="clearBalanceRejectedDialog" content-class="clear-balance-rejected">
        <template #title>{{ $translate('paymentFlow.amount.clearBalanceRejectedTitle') }}</template>
        <template #text>{{ $translate('paymentFlow.amount.clearBalanceRejectedText', { provider: currentProvider.name, phoneNumber: currentProvider.billingPhoneNumber }) }}</template>
        <template #actions>
            <base-btn class="continue-btn v-button-primary" variant="text" @click="clearBalanceRejectedDialog = false">{{ $translate('actions.close') }}</base-btn>
        </template>
    </base-dialog>
</div>
</template>
<script>
import _ from 'lodash';

import { states } from './../utils/utils.js';
import NewFlowPayment from './../models/NewFlowPayment';
import Bill from './../models/Bill';

import CardHeader from './CardHeader.vue';
import NeedHelp from './NeedHelp.vue';
import PaymentFlowAccessOneStep from './PaymentFlowAccessOneStep.vue';
import PaymentFlowAmountStep from './PaymentFlowAmountStep.vue';
import PaymentFlowConfirmationStep from './PaymentFlowConfirmationStep.vue';
import PaymentFlowCuraeAcceptStep from './PaymentFlowCuraeAcceptStep.vue';
import PaymentFlowCuraeApplicationStep from './PaymentFlowCuraeApplicationStep.vue';
import PaymentFlowCuraeExistingStep from './PaymentFlowCuraeExistingStep.vue';
import PaymentFlowCuraeOffersStep from './PaymentFlowCuraeOffersStep.vue';
import PaymentFlowDateStep from './PaymentFlowDateStep.vue';
import PaymentFlowMethodStep from './PaymentFlowMethodStep.vue';
import BaseBottomSheet from './BaseBottomSheet.vue';
import BaseDialog from './BaseDialog.vue';
import BaseIcon from './BaseIcon.vue';
import BaseSpacer from './BaseSpacer.vue';
import BaseBtn from './BaseBtn.vue';

export default {
    name: 'PaymentFlowContent',
    components: {
        CardHeader,
        NeedHelp,
        PaymentFlowAccessOneStep,
        PaymentFlowAmountStep,
        PaymentFlowConfirmationStep,
        PaymentFlowCuraeAcceptStep,
        PaymentFlowCuraeApplicationStep,
        PaymentFlowCuraeExistingStep,
        PaymentFlowCuraeOffersStep,
        PaymentFlowDateStep,
        PaymentFlowMethodStep,
        BaseBottomSheet,
        BaseDialog,
        BaseIcon,
        BaseBtn,
        BaseSpacer
    },
    data: () => ({
        billPaymentOptions: null,  // non-null implies account-level payment
        cameFromBillSummary: false,
        cameFromEstimateSummary: false,
        cancelPaymentDialog: false,
        clearBalancePendingDialog: false,
        clearBalancePollingPromise: null,
        clearBalanceRejectedDialog: false,
        confirmationGenericError: null,
        confirmationMethodError: null,
        curaeCardholderAgreement: null,
        curaePaymentFormId: null,
        currentAccount: null,
        currentSubAccount: null,
        currentEstimate: null,
        existingCuraeApplication: {},
        financingError: null,
        financingRedirectUrl: null,
        isGettingFinancingOptions: false,
        isProcessing: false,
        needHelpBottomSheet: false,
        noOffersFoundDialog: false,
        noOffersFound: false,
        payment: null,
        redirectingDialog: false,
        savedMethods: [],
        savedMethodsLoaded: false,
        selectedCuraeOption: {},
        selectedCuraeOptionIndex: 0,
        validAccessOneFinancingOptions: [],
        validCuraeFinancingOptions: [],
        states: states
    }),
    computed: {
        cardProcessingAvailable() {
            return this.$store.getters.cardProcessingAvailable;
        },
        currentPaymentData() {
            return this.$store.getters.currentPaymentData;
        },
        currentProvider() {
            return this.$store.getters.currentProvider;
        },
        currentBill() {
            return this.$store.getters.currentBill;
        },
        currentState() {
            return this.$store.getters.currentState;
        },
        currentUser() {
            return this.$store.getters.currentUser;
        },
        eCheckProcessingAvailable() {
            return this.$store.getters.eCheckProcessingAvailable;
        },
        financingProcessingAvailable() {
            return this.$store.getters.financingProcessingAvailable;
        },
        providers() {
            return this.$store.getters.providers;
        },
        isGuestPay() {
            return this.$store.getters.isGuestPay;
        },
        isQuickPay() {
            return this.$store.getters.isQuickPay;
        },
        quickPayType() {
            return this.$store.getters.quickPayType;
        },
        hasBadDebt() {
            return this.currentAccount ? this.currentAccount.isBadDebt : false;
        },
        hasFinancingDefault() {
            return this.currentAccount ? this.currentAccount.isFinancingDefault : false;
        },
        allowPlanUpdate() {
            return Boolean(this.currentAccount) && Boolean(this.currentAccount.currentPlan) && this.currentAccount.currentPlan.allowPlanUpdate;
        },
        allowEstimatePartialPayments() {
            return this.currentProvider.features.includes('allowEstimatePartialPayments');
        },
        estimateRequestedAmount() {
            if (this.currentEstimate && this.currentEstimate.requestedPaymentAmount && this.currentEstimate.estimatedAmount >= this.currentEstimate.amountDue) {
                if (this.currentEstimate.requestedPaymentAmount !== this.currentEstimate.amountDue) {
                    return this.currentEstimate.requestedPaymentAmount;
                }
            }
            return null;
        },
        estimateRequestedTotal() {
            if (this.currentEstimate && this.currentEstimate.estimatedAmount) {
                return this.currentEstimate.estimatedAmount;
            }
            return null;
        },
        estimateRequestedAmountPercent() {
            if (this.estimateRequestedAmount) {
                return Math.round(this.currentEstimate.requestedPaymentAmount / this.currentEstimate.estimatedAmount * 100);
            }
            return null;
        },
        financingVendors() {
            if (this.billPaymentOptions && this.currentUser) {
                return this.providerDetails.allFinancingPartners.filter(partner => !this.currentUser.recentDeniedFinancingVendors.includes(partner));
            }
            return [];
        },
        providerDetails() {
            if (this.currentBill && this.currentBill.providerDetails) {
                return this.currentBill.providerDetails;
            } else if (this.currentAccount && this.currentAccount.bills && this.currentAccount.bills[0] && this.currentAccount.bills[0].providerDetails) {
                return this.currentAccount.bills[0].providerDetails;
            } else if (this.currentAccount &&  this.currentAccount.estimates && this.currentAccount.estimates[0] && this.currentAccount.estimates[0].providerDetails) {
                return this.currentAccount.estimates[0].providerDetails;
            } else {
                return this.currentProvider;
            }
        },
        maxScheduleDays() {
            if (this.providerDetails && this.providerDetails.limits && this.providerDetails.limits.paymentMaxScheduleDays) {
                return this.providerDetails.limits.paymentMaxScheduleDays;
            }
            return null;
        },
        financingDisclaimers() {
            return this.providerDetails.disclaimers;
        },
        minAmountForTransaction() {
            return this.providerDetails.minAmountForTransaction;
        },
        hasApplePayEnabled() {
            if (!this.currentEstimate) {
                return this.currentProvider.features.includes('applePay');
            }
            return false;
        },
        minPartialAmount() {
            if (this.providerDetails && this.providerDetails.limits && this.providerDetails.limits.paymentMinAmount) {
                return this.providerDetails.limits.paymentMinAmount;
            }
            return null;
        },
        usaepayPublicKey() {
            return this.providerDetails.usaepayPublicKey;
        },
        nextState() {
            if (this.payment.isAmountValid() && this.payment.financingVendor && !this.isGuestPay) {
                if ('AccessOne' === this.payment.financingVendor) {
                    return 'AccountPaymentFlowAccessOneStep';
                } else if ('Curae' === this.payment.financingVendor) {
                    if (!this.validCuraeFinancingOptions || !this.validCuraeFinancingOptions.length) {
                        return 'AccountPaymentFlowCuraeApplicationStep';
                    } else if (this.validCuraeFinancingOptions && !Object.keys(this.selectedCuraeOption).length) {
                        if (this.validCuraeFinancingOptions[0].isExistingAccount) {
                            return 'AccountPaymentFlowCuraeExistingStep';
                        }
                        return 'AccountPaymentFlowCuraeOffersStep';
                    } else if (Object.keys(this.selectedCuraeOption).length) {
                        return 'AccountPaymentFlowCuraeAcceptStep';
                    }
                }
            }
            if (this.payment.isAmountValid() && (this.payment.isDateValid() || this.shouldHideDate) && this.payment.isMethodValid()) {
                if (this.currentSubAccount) {
                    return this.isGuestPay ? 'GuestSubAccountPaymentFlowConfirmationStep' : 'SubAccountPaymentFlowConfirmationStep';
                } else if (this.currentEstimate) {
                    return 'EstimatePaymentFlowConfirmationStep';
                } else {
                    return this.isGuestPay ? 'GuestAccountPaymentFlowConfirmationStep' : 'AccountPaymentFlowConfirmationStep';
                }
            }
            if (this.payment.isAmountValid() && (this.payment.isDateValid() || this.shouldHideDate)) {
                if (this.currentSubAccount) {
                    return this.isGuestPay ? 'GuestSubAccountPaymentFlowMethodStep' : 'SubAccountPaymentFlowMethodStep';
                } else if (this.currentEstimate) {
                    return 'EstimatePaymentFlowMethodStep';
                } else {
                    return this.isGuestPay ? 'GuestAccountPaymentFlowMethodStep' : 'AccountPaymentFlowMethodStep';
                }
            }
            if (this.payment.isAmountValid()) {
                if (this.currentSubAccount) {
                    return this.isGuestPay ? 'GuestSubAccountPaymentFlowDateStep' : 'SubAccountPaymentFlowDateStep';
                } else if (this.currentEstimate) {
                    return 'EstimatePaymentFlowDateStep';
                } else {
                    return this.isGuestPay ? 'GuestAccountPaymentFlowDateStep' : 'AccountPaymentFlowDateStep';
                }
            }
            return this.isGuestPay ? 'BillSummary' : 'AccountDetails';
        },
        hasFinancingOption() {
            if (this.currentAccount && this.currentAccount.bills && this.currentAccount.bills[0]) {
                var billSource = {
                    hasFinancing: this.currentAccount.bills[0].hasFinancing,
                    providerDetails: this.currentAccount.bills[0].providerDetails,
                }
                var bill = new Bill(billSource);
                return bill.hasFinancingOption(); // will be true if above min to finance
            }
            return false;
        },
        shouldPreventPaymentPlanCreation() {
            if (this.payment.estimateId) {
                return 'PAYMENT_PLAN' === this.currentEstimate.subAccountStatus;
            } else if (!this.payment.voucherNumber && this.currentUser) {
                // Don't allow a new payment plan if one already exists for the account, regardless of the status
                if (this.currentAccount.currentPlan) {
                    return true;
                }
                // Don't allow payment plan creation if there's already a promise-to-pay plan on the account
                if (this.currentAccount.promiseToPayPlan && ['ACTIVE', 'HELD'].includes(this.currentAccount.promiseToPayPlan.status)) {
                    return true;
                }
                // If the provider has the singlePaymentPlan feature enabled, check that no other accounts have a plan for that provider
                if (this.providerDetails.hasSinglePaymentPlan) {
                    return this.currentUser.paymentPlanProviders.includes(this.currentProvider.id);
                }
                // Apply Curae payment plan logic to allow payment plans if recently denied and below minimum to finance
                if (this.providerDetails.allFinancingPartners && this.providerDetails.allFinancingPartners.includes("Curae")) {
                    var recentlyDeclined = this.currentUser.recentDeniedFinancingVendors.includes("Curae");
                    var allowsPaymentPlans = this.providerDetails.financingPartnersAllowingPaymentPlans.includes("Curae");
                    if (allowsPaymentPlans) {
                        return false;
                    } else if (!allowsPaymentPlans) {
                        // allow financing in this case even if payment plans not allowed
                        // hasFinancingOption is false if amount due is below min to finance
                        if (recentlyDeclined || !this.hasFinancingOption) {
                            return false;
                        }
                    }
                    return true;
                }
            }
            return this.currentAccount.isBadDebt;
        },
        shouldHideDate() {
            if (!this.maxScheduleDays || (!this.currentEstimate && this.currentAccount.isBadDebt)) {
                let today = this.$formatDate(new Date(), 'YYYY-MM-DD', null);
                this.payment.setDate(this.$formatDate(today, 'YYYY/MM/DD', 'YYYY-MM-DD'));
                return true;
            }
            return false;
        },
        subAccountId() {
            return this.$route.params.subAccountId;
        },
        estimateId() {
            return this.$route.params.estimateId;
        },
        routeName() {
            return this.$route.name;
        },
        alreadyHasFinancing() {
            if ((this.savedMethods && this.savedMethods.length) && (this.financingVendors && this.financingVendors.length)) {
                var existingFinancingMethods = this.savedMethods.filter((method) => {
                    return this.financingVendors.includes(method.vendor);
                });
                if (existingFinancingMethods.length) {
                    return true;
                }
            }
            return false;
        },
        installmentOptions() {
            if (this.currentEstimate) {
                return this.currentProvider.paymentPrePlanInstallmentOptions;
            } else {
                return this.currentProvider.paymentPlanInstallmentOptions;
            }
        },
    },
    methods: {
        // Methods triggered by child routes
        back() {
            this.confirmationMethodError = null;
            this.routeToPreviousStep();
        },
        cancel() {
            if (this.isQuickPay) {
                this.$router.push({ name: 'QuickPay' });
            } else if (this.cameFromBillSummary || this.isGuestPay) {
                this.$router.push({ name: 'BillSummary' });
            } else if (this.cameFromEstimateSummary) {
                this.$router.push({ name: 'EstimateSummary' });
            } else {
                this.$router.push({
                    name: 'AccountDetails',
                    params: {
                        accountId: this.currentAccount.accountId,
                        providerId: this.currentProvider.id,
                    },
                });
            }
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        leaveCurae() {
            this.savedMethods.push({vendor: "Curae"});
            this.emitter.emit('payment-flow-go-to-step', {
                    name: this.$translate('paymentFlow.steps.amount'),
                    path: 'AccountPaymentFlowAmountStep',
                }
            );
        },
        cancelCurae() {
            this.$router.push({
                name: 'AccountDetails',
                params: {
                    accountId: this.currentAccount.accountId,
                    providerId: this.currentProvider.id,
                },
            });
        },
        leaveFinancingFlow() {
            if (this.isCuraeApplicationStep()) {
                this.back();
            }
        },
        async getValidAccessOneFinancingOptions() {
            let options = [];
            try {
                this.isGettingFinancingOptions = true;
                options = await this.$store.dispatch('getFinancingOptions', {
                    secureCode: this.currentAccount.secureCode,
                    vendors: this.financingVendors,
                });
            } finally {
                this.isGettingFinancingOptions = false;
            }
            if (options.AccessOne && Object.keys(options.AccessOne).length) {
                options = Object.values(options.AccessOne.options);
            }
            return options;
        },
        async getValidCuraeFinancingOptions(application) {
            let options = [];
            try {
                this.isGettingFinancingOptions = true;
                options = await this.$store.dispatch('getFinancingOptions', application);
            } finally {
                this.isGettingFinancingOptions = false;
            }
            if (options.curae && Object.keys(options.Curae).length) {
                options = Object.values(options.Curae.options);
            }
            return options;
        },
        async processPaymentInternal(guestPayEmail = null) {
            if (this.isGuestPay) {
                this.payment.email = guestPayEmail;
            }
            try {
                this.isProcessing = true;
                this.confirmationGenericError = null;
                this.confirmationMethodError = null;
                const result = await this.$store.dispatch('processPayment', { payment: this.payment });
                result.providerId = this.currentProvider.id;
                if (!this.isGuestPay) {
                    this.$store.dispatch('flushAcctActivity', this.currentAccount.accountNumber);
                } else {
                    this.$store.commit('setProcessedGuestPayment', {result: result, payment: this.payment, account: this.currentAccount, subAccount: this.currentSubAccount});
                }
                this.$store.commit('setCurrentBill', null);
                if (this.currentProvider.features.includes('npsInApp')) {
                    this.emitter.emit('feedbackModal:showPrompt', {
                        receipt: result,
                        providerName: this.currentProvider.name,
                    });
                }
                if (this.payment.installmentCount) {
                    this.$router.push({
                        name: 'PlanReceipt',
                        params: {
                            planId: result.planId,
                            providerId: result.providerId,
                        },
                    });
                } else if (this.isGuestPay) {
                    this.$router.push({
                        name: 'GuestReceipt',
                        params: {
                            paymentId: result.paymentId,
                        },
                    });
                } else {
                    this.$router.push({
                        name: 'Receipt',
                        params: {
                            paymentId: result.paymentId,
                            providerId: result.providerId,
                        },
                    });
                }
            } catch (e) {
                console.error('Exception trying to process payment internal:', e)
                const errorCode = e ? e.errorCode : 'UNKNOWN_ERROR';
                switch (errorCode) {
                    case 'METHOD_UNAVAILABLE':
                        // set it initially to all in case the server/frontend are out of sync on what is available
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.methodUnavailableAll', { phoneNumber: this.currentProvider.billingPhoneNumber });
                        if (this.cardProcessingAvailable) {
                            this.confirmationMethodError = this.$translate('paymentFlow.errors.methodUnavailableECheck', { phoneNumber: this.currentProvider.billingPhoneNumber });
                        } else if (this.eCheckProcessingAvailable) {
                            this.confirmationMethodError = this.$translate('paymentFlow.errors.methodUnavailableCard', { phoneNumber: this.currentProvider.billingPhoneNumber });
                        }
                        break;
                    case 'PREMATURE_EXPIRATION':
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.prematureExpiration');
                        break;
                    case 'PAYMENT_INVALID_CARD_NUMBER':
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.paymentInvalidCard');
                        break;
                    case 'PAYMENT_CARD_EXPIRED':
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.paymentCardExpired');
                        break;
                    case 'PAYMENT_DECLINED':
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.paymentDeclined');
                        break;
                    case 'PAYMENT_DUPLICATE':
                        this.confirmationGenericError = this.$translate('paymentFlow.errors.paymentDuplicate', { phoneNumber: this.currentProvider.billingPhoneNumber });
                        break;
                    case 'INVALID_PARAMETERS':
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.invalidParameters');
                        break;
                    case 'PAYMENT_PROCESSING_ERROR':
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.paymentProcessingError', { phoneNumber: this.currentProvider.billingPhoneNumber });
                        break;
                    case 'ACCOUNT_PAID_IN_FULL':
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.accountPaidInFull');
                        break;
                    case 'NETWORK_ERROR':
                        this.confirmationMethodError = this.$translate('paymentFlow.errors.networkError', { phoneNumber: this.currentProvider.billingPhoneNumber });
                        break;
                    case 'UNKNOWN_ERROR':
                    default:
                        this.confirmationGenericError = this.$translate('paymentFlow.errors.generic', { phoneNumber: this.currentProvider.billingPhoneNumber });
                        break;
                }
            } finally {
                this.isProcessing = false;
            }
        },
        updateAmount({ amount, amountOptionType, financingVendor, installmentCount, maxDateForDiscountPolicy }) {
            this.payment.setAmount(amount.replace(",", ""));
            this.payment.setAmountOptionType(amountOptionType);
            this.payment.setFinancingVendor(financingVendor);
            this.payment.setInstallmentCount(installmentCount);
            this.payment.setMaxDateForDiscountPolicy(maxDateForDiscountPolicy);
            if (parseFloat(this.payment.amount) > 0) {
                this.continueToNextStep();
            }
        },
        updateDate(date) {
            this.payment.setDate(this.$formatDate(date, 'YYYY/MM/DD', 'YYYY-MM-DD'));
            this.continueToNextStep();
        },
        updateMethod({ billing, method }) {
            this.payment.setBilling(billing);
            this.payment.setMethod(method);
            this.continueToNextStep();
        },
        // Internal methods
        closeBottomSheet() {
            this.needHelpBottomSheet = false;
        },
        composeMessage() {
            this.closeBottomSheet();
            this.emitter.emit('message:compose', { secureCode: this.currentAccount.secureCode });
        },
        async continueToNextStep() {
            if ('AccountPaymentFlowAccessOneStep' === this.nextState) {
                try {
                    this.validAccessOneFinancingOptions = await this.getValidAccessOneFinancingOptions();
                    if (!this.validAccessOneFinancingOptions || !this.validAccessOneFinancingOptions.length) {
                        this.validAccessOneFinancingOptions = [];
                        return;
                    }
                } finally {
                    if (!(this.validAccessOneFinancingOptions && this.validAccessOneFinancingOptions.length)) {
                        this.noOffersFoundDialog = true;
                        return;
                    }
                }
            }
            if (this.payment.isAmountValid() && 'ClearBalance' === this.payment.financingVendor) {
                this.redirectToClearBalance();
                // ClearBalance does not have another step in the payment flow after Amount Step
                return;
            }

            this.$router.push({ name: this.nextState }).catch(err => {
                // Ignore the vuex err regarding  navigating to the page they are already on.
                if (err.name !== 'NavigationDuplicated') {
                    // But print any other errors to the console
                    console.error("There was an error trying to navigate to the next page", err);
                }
            });
        },
        async getCuraeOffers({application, data}) {
            this.isGettingFinancingOptions = true;
            this.financingError = null;
            this.existingCuraeApplication = data;
            try {
                const options = await this.getValidCuraeFinancingOptions(application);
                this.validCuraeFinancingOptions = [];
                if (options && options.Curae && options.Curae.options) {
                    this.validCuraeFinancingOptions = options.Curae.options;
                }
                if (!this.validCuraeFinancingOptions || !this.validCuraeFinancingOptions.length) {
                    this.currentUser.recentDeniedFinancingVendors.push("Curae");
                    this.validCuraeFinancingOptions = [];
                    this.noOffersFoundDialog = true;
                    this.noOffersFound = true;
                    return;
                }
                this.continueToNextStep();
            } catch (e) {
                this.financingError = this.$translate('payment.amount.getFinancingOptionsErrorMessage');
                if (e && e.data && e.data.error) {
                    var translateKey = 'payment.financing.errors.' + e.data.error;
                    var errorMsg = this.$translate(translateKey);
                    if (e.data.error !== 'tryAgain') {
                        this.financingError = errorMsg;
                    }
                }
            } finally {
                this.isGettingFinancingOptions = false;
            }
        },
        async selectCuraeOffer({option, index}) {
            this.financingError = null;
            this.selectedCuraeOption = option;
            this.selectedCuraeOptionIndex = index;
            this.isGettingFinancingOptions = true;
            try {
                var response = await this.$store.dispatch('chooseFinancingOption', {
                    vendor: 'Curae',
                    option: this.selectedCuraeOption,
                    secureCode: this.currentAccount.secureCode,
                });
                this.curaeCardholderAgreement = response.cardholderAgreement;
                this.curaePaymentFormId = response.paymentFormId;
            } catch {
                this.financingError = this.$translate('payment.financing.offers.chooseFinancingOptionErrorMessage');
            } finally {
                this.isGettingFinancingOptions = false;
            }
            this.continueToNextStep();
        },
        async continueWithAccessOne(option) {
            this.isGettingFinancingOptions = true;
            this.financingError = null;
            try {
                const res = await this.$store.dispatch('chooseFinancingOption', {
                    vendor: 'AccessOne',
                    option,
                    secureCode: this.currentAccount.secureCode,
                });
                this.financingRedirectUrl = res.redirectUrl;
                this.redirectingDialog = true;
                window.setTimeout(() => {
                    // Try to open a new tab, but if that don't work then redirect in current tab
                    const newWin = window.open(res.redirectUrl, '_blank');
                    if (!newWin || newWin.closed || typeof newWin.closed === 'undefined') {
                        window.location.href = res.redirectUrl;
                    }
                }, 4000);
            } catch {
                this.financingError = this.$translate('paymentFlow.financing.accessOne.error');
            } finally {
                this.isGettingFinancingOptions = false;
            }
        },
        async continueWithCurae({ cardholderAgreementCheck, authorizeTransactionCheck, cardholderAgreementUrl }) {
            this.isGettingFinancingOptions = true;
            this.financingError = null;
            try {
                var payload = {
                    authorizeTransactionCheck: authorizeTransactionCheck,
                    cardholderAgreementCheck: cardholderAgreementCheck,
                    cardholderAgreement: this.curaeCardholderAgreement,
                    cardholderAgreementUrl: cardholderAgreementUrl,
                    paymentFormId: this.curaePaymentFormId,
                    secureCode: this.currentAccount.secureCode,
                    vendor: 'Curae'
                }
                const chargeFinancingResponse = await this.$store.dispatch('chargeFinancingPaymentFormFirstTime', payload);
                if (this.currentProvider.features.includes('npsInApp')) {
                    this.emitter.emit('feedbackModal:showPrompt', {
                        receipt: chargeFinancingResponse,
                        providerName: this.currentProvider.name,
                    });
                }
                this.$router.push({
                    name: 'Receipt',
                    params: {
                        providerId: this.currentProvider.id,
                        paymentId: chargeFinancingResponse.paymentId,
                    },
                });
            } catch {
                this.financingError = this.$translate('payment.financing.offers.chooseFinancingOptionErrorMessage');
            } finally {
                this.isGettingFinancingOptions = false;
            }
        },
        async existingCuraeAccount({ authorizeTransactionCheck, paymentFormId }) {
            this.isGettingFinancingOptions = true;
            this.financingError = null;
            try {
                let payload = {
                    authorizeTransactionCheck: authorizeTransactionCheck,
                    paymentFormId: paymentFormId,
                    secureCode: this.currentAccount.secureCode
                }
                const chargeFinancingResponse = await this.$store.dispatch('chargeExistingFinancingAccount', payload);
                if (this.currentProvider.features.includes('npsInApp')) {
                    this.emitter.emit('feedbackModal:showPrompt', {
                        receipt: chargeFinancingResponse,
                        providerName: this.currentProvider.name,
                    });
                }
                this.$router.push({
                    name: 'Receipt',
                    params: {
                        providerId: this.currentProvider.id,
                        paymentId: chargeFinancingResponse.paymentId,
                    },
                });
            } catch {
                this.financingError = this.$translate('payment.financing.offers.chooseFinancingOptionErrorMessage');
            } finally {
                this.isGettingFinancingOptions = false;
            }
        },
        setIsGettingFinancingOptions(value) {
            this.isGettingFinancingOptions = value;
        },
        isStepValid(stepName) {
            switch (stepName) {
                case 'AccountPaymentFlowAmountStep':
                case 'EstimatePaymentFlowAmountStep':
                case 'SubAccountPaymentFlowAmountStep':
                    return !this.isGuestPay;
                case 'GuestAccountPaymentFlowAmountStep':
                case 'GuestSubAccountPaymentFlowAmountStep':
                    return this.isGuestPay;
                case 'AccountPaymentFlowAccessOneStep':
                    return !this.isGuestPay && this.payment.isAmountValid() && this.payment.financingVendor == 'AccessOne';
                case 'AccountPaymentFlowCuraeApplicationStep':
                case 'AccountPaymentFlowCuraeOffersStep':
                case 'AccountPaymentFlowCuraeExistingStep':
                    return !this.isGuestPay && this.payment.isAmountValid() && this.payment.financingVendor == 'Curae';
                case 'AccountPaymentFlowCuraeAcceptStep':
                    return !this.isGuestPay && this.payment.isAmountValid() && this.payment.financingVendor == 'Curae' && !_.isEmpty(this.selectedCuraeOption);
                case 'AccountPaymentFlowDateStep':
                case 'EstimatePaymentFlowDateStep':
                case 'SubAccountPaymentFlowDateStep':
                    return !this.isGuestPay && this.payment.isAmountValid();
                case 'GuestAccountPaymentFlowDateStep':
                case 'GuestSubAccountPaymentFlowDateStep':
                    return this.isGuestPay && this.payment.isAmountValid();
                case 'AccountPaymentFlowMethodStep':
                case 'EstimatePaymentFlowMethodStep':
                case 'SubAccountPaymentFlowMethodStep':
                    return !this.isGuestPay && this.payment.isAmountValid() && (this.payment.isDateValid() || this.shouldHideDate);
                case 'GuestAccountPaymentFlowMethodStep':
                case 'GuestSubAccountPaymentFlowMethodStep':
                    return this.isGuestPay && this.payment.isAmountValid() && (this.payment.isDateValid() || this.shouldHideDate);
                case 'GuestAccountPaymentFlowConfirmationStep':
                case 'GuestSubAccountPaymentFlowConfirmationStep':
                    return this.isGuestPay && this.payment.isAmountValid() && (this.payment.isDateValid() || this.shouldHideDate) && this.payment.isMethodValid();
                case 'AccountPaymentFlowConfirmationStep':
                case 'EstimatePaymentFlowConfirmationStep':
                case 'SubAccountPaymentFlowConfirmationStep':
                default:
                    return !this.isGuestPay && this.payment.isAmountValid() && (this.payment.isDateValid() || this.shouldHideDate) && this.payment.isMethodValid();
            }
        },
        async loadSavedMethods() {
            this.savedMethods = [];
            if (this.currentUser) {
                this.savedMethods = await this.$store.dispatch('fetchSavedMethods', { filterExpired: false });
                this.savedMethodsLoaded = true;
            }
        },
        async redirectToClearBalance() {
            this.isGettingFinancingOptions = false;
            try {
                const redirectUrl = await this.$store.dispatch('getFinancingRedirectUrl', { secureCode: this.currentAccount.secureCode, vendor: 'ClearBalance' });
                this.financingRedirectUrl = redirectUrl;
                this.redirectingDialog = true;
                window.setTimeout(() => {
                    // Try to open a new tab, but if that don't work then redirect in current tab
                    window.open(redirectUrl, '_blank');
                    if (!this.clearBalancePollingPromise) {
                        this.redirectingDialog = false;
                        this.clearBalancePendingDialog = true;
                        this.clearBalancePollingPromise = window.setInterval(async () => {
                            if (!this.clearBalancePollingPromise) {
                                return;
                            }
                            this.$store.dispatch('getFinancingStatus', { secureCode: this.currentAccount.secureCode, vendor: 'ClearBalance' }).then((status) => {
                                if ('APPROVED' === status) {
                                    window.clearInterval(this.clearBalancePollingPromise);
                                    this.clearBalancePendingDialog = false;
                                    this.$router.push({
                                        name: 'AccountDetails',
                                        params: {
                                            accountId: this.currentAccount.accountId,
                                            providerId: this.currentProvider.id,
                                        },
                                        query: {
                                            clearBalanceSuccess: true,
                                        }
                                    });
                                } else if ('REJECTED' === status || 'DECLINED' === status) {
                                    window.clearInterval(this.clearBalancePollingPromise);
                                    this.clearBalancePendingDialog = false;
                                    this.clearBalanceRejectedDialog = true;
                                }
                            }).catch((err) => {
                                console.error('Error: ' + err);
                                window.clearInterval(this.clearBalancePollingPromise);
                            });
                        }, 3000);
                    }
                }, 3000);
            } catch {
                this.noOffersFoundDialog = true;
            } finally {
                this.isGettingFinancingOptions = false;
            }
        },
        routeToPaymentStep(step) {
            if (this.isStepValid(step.path) && step.path !== this.routeName) {
                this.$router.push({ name: step.path });
            }
        },
        routeToPreviousStep() {
            let previousStepName;
            if (this.isCuraeApplicationStep() || this.isCuraeOffersStep() || this.isCuraeAcceptStep()) {
                this.financingError = null;
                if (this.isCuraeApplicationStep()) {
                    this.validCuraeFinancingOptions = [];
                }
                if (this.isCuraeOffersStep()) {
                    this.selectedCuraeOption = {};
                }
            }
            switch (this.routeName) {
                case 'AccountPaymentFlowAmountStep':
                case 'GuestAccountPaymentFlowAmountStep':
                    if (this.isQuickPay) {
                        previousStepName = 'QuickPay';
                    } else if (this.cameFromBillSummary || this.isGuestPay) {
                        previousStepName = 'BillSummary';
                    } else {
                        previousStepName = 'AccountDetails';
                    }
                    break;
                case 'AccountPaymentFlowDateStep':
                case 'GuestAccountPaymentFlowDateStep':
                    previousStepName = this.isGuestPay ? 'GuestAccountPaymentFlowAmountStep' : 'AccountPaymentFlowAmountStep';
                    break;
                case 'AccountPaymentFlowMethodStep':
                case 'GuestAccountPaymentFlowMethodStep':
                    if (this.shouldHideDate) {
                        previousStepName = this.isGuestPay ? 'GuestAccountPaymentFlowAmountStep' : 'AccountPaymentFlowAmountStep';
                    } else {
                        previousStepName = this.isGuestPay ? 'GuestAccountPaymentFlowDateStep' : 'AccountPaymentFlowDateStep';
                    }
                    break;
                case 'AccountPaymentFlowConfirmationStep':
                case 'GuestAccountPaymentFlowConfirmationStep':
                    previousStepName = this.isGuestPay ? 'GuestAccountPaymentFlowMethodStep' : 'AccountPaymentFlowMethodStep';
                    break;
                case 'SubAccountPaymentFlowAmountStep':
                case 'GuestSubAccountPaymentFlowAmountStep':
                    if (this.isQuickPay) {
                        previousStepName = 'QuickPay';
                    } else if (this.cameFromBillSummary || this.isGuestPay) {
                        previousStepName = 'BillSummary';
                    } else {
                        previousStepName = 'AccountDetails';
                    }
                    break;
                case 'SubAccountPaymentFlowDateStep':
                case 'GuestSubAccountPaymentFlowDateStep':
                    previousStepName = this.isGuestPay ? 'GuestSubAccountPaymentFlowAmountStep' : 'SubAccountPaymentFlowAmountStep';
                    break;
                case 'SubAccountPaymentFlowMethodStep':
                case 'GuestSubAccountPaymentFlowMethodStep':
                    if (this.shouldHideDate) {
                        previousStepName = this.isGuestPay ? 'GuestSubAccountPaymentFlowAmountStep' : 'SubAccountPaymentFlowAmountStep';
                    } else {
                        previousStepName = this.isGuestPay ? 'GuestSubAccountPaymentFlowDateStep' : 'SubAccountPaymentFlowDateStep';
                    }
                    break;
                case 'SubAccountPaymentFlowConfirmationStep':
                case 'GuestSubAccountPaymentFlowConfirmationStep':
                    previousStepName = this.isGuestPay ? 'GuestSubAccountPaymentFlowMethodStep' : 'SubAccountPaymentFlowMethodStep';
                    break;
                case 'EstimatePaymentFlowAmountStep':
                    if (this.cameFromEstimateSummary) {
                        previousStepName = 'EstimateSummary';
                    } else {
                        previousStepName = 'AccountDetails';
                    }
                    break;
                case 'EstimatePaymentFlowDateStep':
                    previousStepName = 'EstimatePaymentFlowAmountStep';
                    break;
                case 'EstimatePaymentFlowMethodStep':
                    if (this.shouldHideDate) {
                        previousStepName = 'EstimatePaymentFlowAmountStep';
                    } else {
                        previousStepName = 'EstimatePaymentFlowDateStep';
                    }
                    break;
                case 'EstimatePaymentFlowConfirmationStep':
                    previousStepName = 'EstimatePaymentFlowMethodStep';
                    break;
                case 'AccountPaymentFlowCuraeExistingStep':
                    previousStepName = 'AccountPaymentFlowCuraeApplicationStep';
                    break;
                case 'AccountPaymentFlowCuraeAcceptStep':
                    previousStepName = 'AccountPaymentFlowCuraeOffersStep';
                    break;
                case 'AccountPaymentFlowCuraeOffersStep':
                    previousStepName = 'AccountPaymentFlowCuraeApplicationStep';
                    break;
                case 'AccountPaymentFlowCuraeApplicationStep': 
                    previousStepName = 'AccountPaymentFlowAmountStep'
                    break;
                case 'AccountPaymentFlowAccessOneStep':
                    previousStepName = 'AccountPaymentFlowAmountStep'
                    break;
                default:
                    if (this.isQuickPay) {
                        previousStepName = 'QuickPay';
                    } else if (this.isGuestPay) {
                        previousStepName = 'BillSummary';
                    } else {
                        previousStepName = 'AccountDetails';
                    }
                    break;
            }
            this.$router.push({ name: previousStepName });
        },
        validateCurrentStepIsValid() {
            if (!this.isStepValid(this.routeName)) {
                if (this.currentSubAccount) {
                    if (this.isGuestPay) {
                        this.$router.replace({ name: 'GuestSubAccountPaymentFlowAmountStep' });
                    } else {
                        this.$router.replace({ name: 'SubAccountPaymentFlowAmountStep' });
                    }
                } else if (this.currentEstimate) {
                    this.$router.replace({ name: 'EstimatePaymentFlowAmountStep' });
                } else {
                    if (this.isGuestPay) {
                        this.$router.replace({ name: 'GuestAccountPaymentFlowAmountStep' });
                    } else {
                        this.$router.replace({ name: 'AccountPaymentFlowAmountStep' });
                    }
                }
            }
        },
        setExistingApplicationForCurae() {
            if (this.currentUser && Object.keys(this.currentUser).length && !Object.keys(this.existingCuraeApplication).length) {
                var guarantorAddress = this.currentAccount.bills[0].getGuarantorAddress();
                var state = states.find(s => {
                    if (guarantorAddress) {
                        return s.value == guarantorAddress.state;
                    }
                    return '';
                });
                this.existingCuraeApplication = {
                    firstName: this.currentUser.firstName,
                    lastName: this.currentUser.lastName,
                    email: this.currentUser.email,
                    phone: this.currentUser.phoneNumber,
                    ssn: '',
                    birthdate: '',
                    secureCode: this.secureCode,
                    vendors: ['Curae'],
                    electronicDisclosureConsent: false,
                    income: '',
                    address: guarantorAddress ? guarantorAddress.address : '',
                    address2: guarantorAddress ? guarantorAddress.address2 : '',
                    city: guarantorAddress ? guarantorAddress.city : '',
                    state: state,
                    zip: guarantorAddress ? guarantorAddress.zip : ''
                };
            }
        },
        onClickEditLink(step) {
            this.confirmationMethodError = null;
            this.emitter.emit('payment-flow-go-to-step', step);
        },
        isAccessOneStep() {
            return 'AccountPaymentFlowAccessOneStep' === this.$route.name;
        },
        isCuraeApplicationStep() {
            return 'AccountPaymentFlowCuraeApplicationStep' === this.$route.name;
        },
        isCuraeOffersStep() {
            return 'AccountPaymentFlowCuraeOffersStep' === this.$route.name;
        },
        isCuraeAcceptStep() {
            return 'AccountPaymentFlowCuraeAcceptStep' === this.$route.name;
        },
        isCuraeExistingStep() {
            return 'AccountPaymentFlowCuraeExistingStep' === this.$route.name;
        },
        isAmountStep() {
            return (
                ('AccountPaymentFlowAmountStep' === this.routeName) ||
                ('SubAccountPaymentFlowAmountStep' === this.routeName) ||
                ('EstimatePaymentFlowAmountStep' === this.routeName)
            );
        },
        isDateStep() {
            return (
                ('AccountPaymentFlowDateStep' === this.routeName) ||
                ('SubAccountPaymentFlowDateStep' === this.routeName) ||
                ('EstimatePaymentFlowDateStep' === this.routeName)
            );
        },
        isMethodStep() {
            return (
                ('AccountPaymentFlowMethodStep' === this.routeName) ||
                ('SubAccountPaymentFlowMethodStep' === this.routeName) ||
                ('EstimatePaymentFlowMethodStep' === this.routeName)
            );
        },
        isConfirmationStep() {
            return (
                ('AccountPaymentFlowConfirmationStep' === this.routeName) ||
                ('SubAccountPaymentFlowConfirmationStep' === this.routeName) ||
                ('EstimatePaymentFlowConfirmationStep' === this.routeName)
            );
        },
        totalAmount() {
            if (this.currentSubAccount) {
                return this.currentSubAccount.amountDue;
            } else if (this.currentEstimate) {
                return this.currentEstimate.amountDue;
            } else {
                return this.currentAccount.amountDue;
            }
        },
        statementAmount() {
            if (this.currentSubAccount) {
                return null;
            } else if (this.currentEstimate) {
                return null;
            } else {
                return this.currentAccount.bills[0].statementAmount;
            }
        },
        isEstimatePayable(estimate) {
            // not payable statuses are ADJUDICATED and INACTIVE
            let statuses = ["CREATED", "PRESERVICE_UNFULFILLED", "PRESERVICE_PARTIAL_FULFILLED", "PRESERVICE_FULFILLED", "PAYMENT_PLAN"];
            return statuses.includes(estimate.subAccountStatus) && (estimate.amountDue > 0);
        }
    },
    async mounted() {
        await this.$nextTick();
        if ((!this.currentUser || !Object.keys(this.currentUser).length) && sessionStorage.getItem("bill")) { // comes from sunday sky video
            const bill = JSON.parse(sessionStorage.getItem("bill"));
            await this.$store.dispatch('findBill', { secureCode: bill.secureCode, billAmount: bill.billAmount });
            this.$router.replace({name: 'BillSummary'});
            const authParams = {};
            const onAuthSuccess = async () => {
                this.$store.dispatch('linkBill', { amount: bill.billAmount, sCode: bill.secureCode }).then(() => {
                    if (bill.providerDetails.requiresRedirectToBranded) {
                        this.emitter.emit('redirect:tab', {
                            provider: bill.providerDetails,
                            context: {
                                stateGo: {
                                    to: 'AccountPaymentFlowAmountStep',
                                    toParams: {
                                        accountId: bill.accountInfo.accountId,
                                        providerId: bill.providerDetails.id
                                    },
                                },
                            },
                        });
                        } else {
                        this.$router.push({
                            name: 'AccountPaymentFlowAmountStep',
                            params: {
                                accountId: bill.accountInfo.accountId,
                                providerId: bill.providerDetails.id
                            },
                        });
                    }
                }).catch((message) => {
                    console.error(message);
                });
            };
            this.emitter.emit('require-authed-user', {params:authParams, successCallback:onAuthSuccess});
            return;
        }

        const accountId = this.$route.params.accountId;
        this.$store.commit('setIsLoadingIndicatorVisible', true);
        let account, provider, subAccount, estimate;
        if (this.isGuestPay) {
            provider = this.currentPaymentData.provider;
            account = this.currentPaymentData.account;
            subAccount = this.currentPaymentData.subAccount;
            estimate = this.currentPaymentData.estimate;
        } else {
            await this.$store.dispatch('getProviders');
            this.providers.forEach((p) => {
                p.accounts.forEach((a) => {
                    if (accountId.toString() === a.accountId.toString()) {
                        provider = p;
                        account = a;
                    }
                });
            });
        }

        // Load the appropriate models based on the type of payment we are taking.
        let routeIsInvalid = !Boolean(account);
        if (!routeIsInvalid) {
            if (this.routeName.includes('SubAccountPayment')) {
                if (this.isGuestPay && subAccount) {
                    // already set, nothing else to do
                } else if (!this.subAccountId || !account.provider.subAccountPaymentsEnabled || !account.bills[0] || !account.bills[0].subAccounts) {
                    routeIsInvalid = true;
                } else {
                    account.bills[0].subAccounts.forEach((sa) => {
                        if (this.subAccountId.toString() === sa.id.toString()) {
                            subAccount = sa;
                        }
                    });
                    if (!subAccount || subAccount.amountDue <= 0) {
                        routeIsInvalid = true;
                    }
                }
            } else if (this.routeName.includes('EstimatePayment')) {
                if (!this.estimateId) {
                    routeIsInvalid = true;
                } else {
                    account.estimates.forEach((es) => {
                        if (this.estimateId.toString() === es.id.toString()) {
                            estimate = es;
                        }
                    });
                    if (!estimate || !this.isEstimatePayable(estimate)) {
                        routeIsInvalid = true;
                    }
                }
            } else if (this.routeName.includes('AccountPayment')) {
                //Check if acccount level payment button should be disabled
                if (!account.amountDue || Boolean(account.financedBy) || !account.provider.active || account.amountDue <= 0) {
                    routeIsInvalid = true;
                }
                //We would not make it this far if we did not have an account, so we're good
            } else {
                routeIsInvalid = true;
            }
        }
        //Kick user back to AccountDetails Page
        if (routeIsInvalid) {
            if (account){
                this.$router.replace({
                    name: 'AccountDetails',
                    params: {
                        accountId: account.accountId,
                        providerId: provider.id,
                    },
                });
            } else {
                this.$router.replace({ name: 'ProvidersSummary' });
            }
            
            return;
        }
        if (document.referrer && document.referrer.includes('/billSummary')) {
            this.cameFromBillSummary = true;
        }
        if (document.referrer && document.referrer.includes('/estimateSummary')) {
            this.cameFromEstimateSummary = true;
        }
        await this.loadSavedMethods();
        this.$store.commit('setCurrentProvider', provider);
        this.currentAccount = account;
        this.currentSubAccount = subAccount;
        this.currentEstimate = estimate;

        if (subAccount) {
            this.payment = new NewFlowPayment({
                secureCode: account.secureCode,
                voucherNumber: subAccount.subAccountNumber,
            });
        } else if (estimate) {
            this.payment = new NewFlowPayment({
                estimateId: Number(this.estimateId),
                secureCode: 'PRESERVICE',
                voucherNumber: estimate.subAccount.subAccountNumber,
            });
        } else {
            if (!account.bills || !account.bills[0]) {
                account.bills = [this.currentBill];
                this.currentAccount = account;
            } else {
                this.$store.commit('setCurrentBill', account.bills[0]);
            }
            this.billPaymentOptions = this.currentBill.paymentOptions;
            this.payment = new NewFlowPayment({
                secureCode: account.secureCode,
            });
            this.setExistingApplicationForCurae();
            const fromGotoFinancing = this.$route.query.financing;
            if (fromGotoFinancing) {
                this.payment.amount = account.bills[0].accountBalance.amount;
                this.payment.amountOptionType = 'CB';
                this.payment.financingVendor = this.financingVendors[0];
            }
            if (this.isQuickPay) {
                if (this.quickPayType == 'CB') {
                    this.updateAmount({
                        amount: account.amountDue.toString(),
                        amountOptionType: this.quickPayType,
                        financingVendor: null,
                        installmentCount: null,
                        maxDateForDiscountPolicy: null
                    });
                } else if (this.quickPayType == 'O') {
                    this.updateAmount({
                        amount: "0",
                        amountOptionType: this.quickPayType,
                        financingVendor: null,
                        installmentCount: null,
                        maxDateForDiscountPolicy: null
                    });
                }
            }
        }
        this.payment.isGuestPay = this.isGuestPay;
        this.$store.commit('setIsLoadingIndicatorVisible', false);
        this.validateCurrentStepIsValid();
        this.emitter.on('payment-flow-go-to-step', this.routeToPaymentStep);
    },
    destroyed() {
        this.emitter.off('payment-flow-go-to-step', this.routeToPaymentStep);
        if (this.clearBalancePollingPromise) {
            window.clearInterval(this.clearBalancePollingPromise);
        }
        this.$store.commit('setIsGuestPay', false);
    },
};
</script>
<style lang="scss">
@import '../styles/variables.scss';
.payment-flow-content.vuetify {
    .payment-flow-content-desktop-header {
        display: flex;
        justify-content: flex-end;
        padding-right: 2.4rem;
        .v-btn.vuetify.need-help-sheet-btn {
            color: $global-color-secondary;
            font-size: 1.2rem;
            padding-top: 0.8rem;
            padding-bottom: 0.6rem;
            padding-left: 1.8rem;
            padding-right: 1.8rem;
            height: unset;
        }
    }
}
</style>
